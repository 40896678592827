<template>
  <div class="mt-9">
    <span class="flex justify-center">
      <h4 class="text-center text-xl">Tipo de moneda</h4>
    </span>
    <div class="flex justify-center my-3">
      <select
        class="bg-white border-2 border-green-300 rounded-md text-center text-xl"
        name=""
        id=""
        v-model="filtro"
        @change="link = ''"
      >
        <option value="usd">Dolares($)</option>
        <option value="bs">Bolivares(Bs)</option>
        <option value="todos">Bolivares(Bs) y dolares($)</option>
      </select>
    </div>
    <enviarLista :filtroMonedaProp="filtro" />
    <div class="flex justify-center w-full mt-12">
      <button
        class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
        @click="generarLista"
      >
        Descargar Lista
      </button>
    </div>
    <div class="flex justify-center w-full mt-12 hidden">
      <a
        class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg text-center"
        :href="link"
        target="_blank"
        ref="descargarLista"
      >
      </a>
    </div>
  </div>
</template>

<script>
import getIdMixin from "@/mixins/getIdMixin";
import enviarLista from "@/components/EnviarListaPrecios";

export default {
  components: {
    enviarLista,
  },
  data() {
    return {
      filtro: "usd",
      link: "",
    };
  },
  mixins: [getIdMixin],
  methods: {
    async generarLista() {
      this.link = "";
      this.$store.state.isLoading = true;
      if (this.$store.state.user.id == 0) {
        await this.getUserId();
      }
      await this.$apollo
        .query({
          query: require("@/graphql/queries/generarPdf.gql"),
          variables: {
            id: this.$store.state.user.id,
            filtro: this.filtro,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.link =
            process.env.VUE_APP_BACKEND_URL + data.data.generarPdf.link;
          setTimeout(() => {
            this.$refs.descargarLista.click();
          }, 200);
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
