export default {
  async created() {
    await this.listaCorreoProveedor();
  },
  methods: {
    async listaCorreoProveedor() {
      this.$store.state.isLoading = true;
      if (this.$store.state.user.id == 0) {
        await this.getUserId();
      }
      await this.$apollo
        .query({
          query: require("@/graphql/queries/listaCorreoProveedor.gql"),
          variables: {
            id: parseInt(this.$store.state.user.id),
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.dataEmail = data.data.listaCorreoProveedor;
          this.keyComponent++;
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
  },
};
