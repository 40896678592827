<template>
  <div class="px-2">
    <span class="flex justify-center">
      <h4 class="text-center text-xl">Enviar Lista</h4>
    </span>
    <div class="flex justify-center my-3">
      <select
        class="bg-white border-2 border-green-300 rounded-md text-center text-xl w-60"
        name=""
        id=""
        v-model="filtroEnviar"
        @change="fillEmailArray"
      >
        <option value="todos">Todos los contactos</option>
        <option value="individual">Seleccionar contactos</option>
      </select>
    </div>
    <div class="flex justify-center" v-if="filtroEnviar != 'todos'">
      <vSelect
        multiple
        class="style-chooser w-60"
        :options="dataEmail"
        :reduce="(name) => name.email"
        :key="keyComponent"
        label="name"
        v-model="emailList"
      ></vSelect>
    </div>
    <div class="flex justify-center mt-12">
      <button
        class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
        @click="enviarLista"
      >
        Enviar Lista (Email)
      </button>
    </div>
  </div>
</template>

<script>
import listaEmailsProveedorMixin from "@/mixins/listaEmailsProveedorMixin";
import getIdMixin from "@/mixins/getIdMixin";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
/* enviar a todos
enviar individual */

export default {
  components: {
    vSelect,
  },
  props: {
    filtroMonedaProp: String,
  },
  mixins: [getIdMixin, listaEmailsProveedorMixin],
  data() {
    return {
      filtroEnviar: "todos",
      dataEmail: [],
      keyComponent: 0,
      emailList: [],
    };
  },
  methods: {
    async enviarLista() {
      if (this.filtroEnviar === "todos") {
        this.emailList = this.dataEmail.map((x) => {
          return x.email;
        });
      }
      if (this.emailList.length > 0) {
        this.$store.state.isLoading = true;
        await this.$apollo
          .query({
            query: require("@/graphql/queries/enviarPdf.gql"),
            variables: {
              id: this.$store.state.user.id,
              filtro: this.filtroMonedaProp,
              mails: this.emailList,
            },
            fetchPolicy: "no-cache",
          })
          .then(() => {
            this.$store.state.isLoading = false;
            alert("¡Lista de precios enviada por email!");
          })
          .catch((error) => {
            this.$store.state.isLoading = false;
            alert("Error al enviar lista de precios.");
            console.log(error);
          });
      } else {
        if (this.filtroEnviar === "todos") {
          alert(
            "No hay emails registrados de los clientes, por favor agregar para poder enviar la lista de precios."
          );
          this.$router.push({
            name: "SaveEmails",
          });
        } else {
          alert("Debe seleccionar al menos un(1) email para poder continuar.");
        }
      }
    },
    fillEmailArray() {
      if (this.filtroEnviar === "todos") {
        this.emailList = this.dataEmail.map((x) => {
          return x.email;
        });
      } else {
        this.emailList = new Array(0);
      }
    },
  },
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffff;
  border-style: solid;
  border-width: 2px;
  border-radius: 0.5rem;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  color: blue;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #797979;
}
</style>
